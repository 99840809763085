body {
    /* background-color: #eaeef3 !important; */
    font-family: "Inter" !important;
}

nav.sidebar {
    padding: 2rem 1rem;
}

.logo {
  margin-bottom: 20px;
  width: 66px;
}

.MuiTableCell-root a {
  color: #783030;
}

nav.navbar a {
    color: white;
}

nav.navbar .dropdown-menu a {
  color: black
}

nav a:hover {
    color: #e3e3e3;
}

nav .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2730%27 height=%2730%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.5%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

.header-logo{
    flex-direction: row !important;
}

.sidebar{
    background-color: #2B2B2B;
    min-width: 250px;
    max-width: 250px;
    min-height: 100vh;
    transition: all 0.3s;
}

.sidebar .ul .li{
    padding: 0.5rem 1rem;
    color: #D1D5DB !important;
    text-decoration: none;
    border-radius: 8px;
}

.sidebar .ul .li svg{
  color: #D1D5DB;
}

.sidebar .ul .li:hover svg {

  color: #10B981;
}

.sidebar .ul .li:hover, .sidebar .ul .active{
    color: #10B981 !important;
    background-color: rgba(255, 255, 255, 0.08);
}
.sidebar ul ul a{
    padding-left: 2.5rem;
    background-color: #1e2835;
}
/*Seletor utilizado para ativar o botão ocultar menu em PC*/ 
.sidebar.toggled{
    margin-left: -250px;
}

@media (max-width: 768px){
    .sidebar{
        margin-left: -250px;
    }
    .sidebar.toggled{
        margin-left: 0px;
    }
}

.sidebar [data-toggle="collapse"]{
    position: relative;
}
.sidebar [data-toggle="collapse"]:before{
    content: "\f0d7";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 1rem;
}
.sidebar [aria-expanded="true"]{
    background-color: #19222d;
}
.sidebar [aria-expanded="true"]:before{
    content: "\f0d8";
}
.content{
    width: 100%;
}
.titulo{
    font-size: 2.0rem !important;
}

.img-perfil{
    position: relative;
    display: inline-block;
}

.edit{
    padding-top: 7px;
    position: absolute;
    padding-right: 7px;
    top: 0px;
    right: 0px;
    display: none;
}

.img-perfil:hover .edit{
    display: block;
}

.cursor-pointer{
    cursor: pointer;
}




/* */
.start-node, .end-node {
    height: 64px;
    width: 64px;
    border-radius: 50%;
    line-height: 64px;
    color: #fff;
    text-align: center;
  }
  
  .start-node {
    background-color: #338aff;
  }
  
  .end-node {
    background-color: #666;
  }
  
  .other-node, .condition-node {
    width: 224px;
    color: #666;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
    padding: 16px;
    text-align: center;
  }
  
  .other-node {
    background-color: #351414;
    display: flex;
    flex-direction: column;
  }
  
  .condition-node {
    background-color: #783030;
  }
  
  .node-status-error {
    box-shadow: 0 0 8px #ff4d4f !important;
  }
  
  .node-configuring {
    box-shadow: 0 0 8px #338aff !important;
  }

  #workflow textarea, #workflow input, #workflow select {
    margin-bottom: 10px;
    padding: 5px 10px;
    border: 0;
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
    width: 100%;
  }

  #workflow textarea::placeholder, #workflow input::placeholder {
    color: rgba(255,255,255, 0.6);
  }

  #workflow .flow-builder-content{
    background-color: transparent !important;
  }

  nav .page-item.active .page-link {
    background-color: #783030;
    border-color: #783030
  }

  .btn.btn-custom-1, .btn.btn-primary{
    background-color: black;
    color: white
  }

  .btn.btn-custom-1:hover, .btn.btn-primary:hover{
    background-color: #351414;
    color: white
  }

  .btn.btn-custom-2{
    background-color: #351414;
    color: white
  }

  .btn.btn-custom-2:hover{
    background-color: #783030;
    color: white
  }

  .btn.btn-outline-info {
    border-color: #783030;
    color: #783030;
  }

  .btn.btn-outline-info:hover {
    background-color: #783030;
    color: white;
    border-color: white;
  }

  a:hover {
    color: #351414;
    text-decoration: none !important;
  }

  .fieldDate {
    margin-top: 20px !important;
  }

  .fieldDate label {
    color: white;
  }

  .fieldDate input {
    color: white
  }

  .optionsForm input, .optionsForm label {
    /* padding: 0px; */
  }

  .other-node h5, .condition-node h5, .other-node small, .condition-node small {
    color: white;
    margin: 0;
  }

  .other-node ul {
    color: white;
    font-size: 12px;
    text-align: left;
  }

  /* */
  .chip-status {
    background-color: rgb(219, 165, 165);
    color: white;
    display: inline;
    font-size: 12px;
    border-radius: 4px;
    position: absolute;
    margin-left: 10px;
    padding: 4px 8px;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
  }

  details summary {
    list-style: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  details summary::-webkit-details-marker {
    display: none;
  }
  
  details summary::marker {
    display: none;
  }

  .card_progress {
    padding: 20px 20px 0px 20px
  }


  button.saveButton, button.saveButton:hover {
    color: white;
    background-color: #008060;
    text-transform: capitalize;
  }

  button.cancelButton, button.cancelButton:hover {
    color: #202223;
    border: 1px solid silver;
    text-transform: capitalize;
    background-color: transparent;
  }

  .alignCenterVertical {
    display: flex;
    align-items: center;
  }

  .clearInput {
    border: 1px solid silver;
    padding: 0px;
  }

  .clearInput.inputH3{
    font-size: 1.75rem;
  }

  .clearInput:focus {
    outline: none;
  }